import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { observer, enableStaticRendering } from 'mobx-react-lite';
import { configure, toJS } from 'mobx';
import { useRouter } from 'next/router';

import {
    installCustomIntlNumberFormat,
    BannerProvider,
    SessionStoreProvider,
    Config,
    PageSettingsProvider,
    AdTypes,
    getVisitorId,
    setLanguageCode,
    defineExperiment,
    isServerSide,
} from '@ratehub/base-ui';
import {
    initializeHeader,
    initializeHamburger,

    initializeFooter,
    initializePopularContentMenu,
    initializeLanguageLinks,
} from '@ratehub/web-components';
import '@ratehub/base-ui/src/styles/GlobalStyles.scss';

import fetchExperimentDefinitions from '../functions/fetchExperimentDefinitions';
import ErrorBoundary from '../components/ErrorBoundary';


// While performing an export, NewRelic isn't available (it's included as a <script> within _document, so only available to the client).
// We want to make sure the `newrelic` global is available on the server as well, so our reporting code can be agnostic.
// NOTE: we don't want this package bundled into the client; we rely on dead branch elimination of `typeof window === 'undefined'`
//       See this link for details https://github.com/vercel/next.js/pull/7651
if (typeof window === 'undefined') {
    global.newrelic = require('newrelic');
}

// When using server side rendering, normal lifecycle hooks of React components are not fired,
// as the components are rendered only once. Since components are never unmounted, observer
// components would in this case leak memory when being rendered server side.
// To avoid leaking memory, call enableStaticRendering(true) when using server side rendering.
// https://github.com/soulmachine/nextjs-starter-kit/blob/master/README.md#33-serverjs
if (typeof window === 'undefined') {
    enableStaticRendering(true);
}

configure({
    // existing usage does not allow to enforce mobx's strict mode so turning it off
    enforceActions: 'never',

    // turning it off until we fix all instances where mobx complains about converting
    // observable objects/arrays to plain objects/arrays (toJS) before mutation
    useProxies: 'never',
});

// temporary monkeypatch to remove unwanted CA following FR $ values
installCustomIntlNumberFormat();


function RatehubApp({ Component, pageProps, pageUrl, pageTranslations, experimentDefinitions }) {
    // PROBLEM: we need the POST-RENDER adCounts within _document, so we can decide to include the ads script.
    // SOLUTION: if we ensure the adCounts object is pre-declared here (instead of created in <PageSettingsContextProvider>), the SAME object will be available in _document (since it's passed the SAME pageProps object).
    // NOTE: this relies on the code within Ad.jsx doing a MUTATION of the pageSettings.adCounts object when it renders.
    if (pageProps.pageSettings == null) {
        pageProps.pageSettings = {};
    }
    if (pageProps.pageSettings.adCounts == null) {
        pageProps.pageSettings.adCounts = {
            [AdTypes.Leaderboard]: 0,
            [AdTypes.Bigbox]: 0,
        };
    }

    const { pageSettings, headerSettings, footerSettings } = pageProps;

    // ISSUE: When we were server rendered, we mutated the adCounts key so we could get unique, ordered indexing with our Ads.
    //        The same object is also read by _document, which uses it to figure out if we need to include our ads script.
    //        The problem is the client is receiving a pre-incremented version of this object, which will throw off the counts.
    // WORK-AROUND: delete the key, to force them to "start over"; it should produce identical indexes on our first render.
    if (typeof window !== 'undefined' && pageSettings) {
        delete pageSettings.adCounts;
    }

    // We need to run the following exactly once, BEFORE we render our components.
    // Because it MUST be run before we do the render, we cannot use useEffect.
    // HACK: (ab)use useState to accomplish what we need.
    useState(() => {
        // Define all our experiments.
        experimentDefinitions?.forEach(experiment => defineExperiment(experiment));
    });

    // Run our post-mount initialization code
    useEffect(() => {
        try {
            // Need to keep this in sync for FaaS functions
            const [ languageCode ] = pageProps.locale.split('-'); // en-CA -> [en, CA]
            if (languageCode?.length > 0) {
                setLanguageCode(languageCode);
            }
        } catch (e) {
            // doesn't work on error/404 pages
        }

        // For screenreaders, ensures other content is hidden while the modal is open
        Modal.setAppElement(document.getElementById('__next'));

        // Add details about the user to Heap, for analytics purposes.
        const visitorId = getVisitorId();
        if (visitorId) {
            // If the user is not identified correctly in Heap, identify the user with visitorId
            if (window?.heap?.identity !== visitorId) {
                window?.heap?.identify(visitorId);
            }

            // REQUIREMENT: ensure the users visitorID is logged in Heap as ratehubVisitorId
            window?.heap?.addUserProperties({ ratehubVisitorId: visitorId });
        }

        // Filter out errors we don't care about.
        // Return `true` to IGNORE it. Return `false` to KEEP it.
        window?.newrelic?.setErrorHandler?.(error => {
            // From logs in NewRelic, there are cases where `error` is undefined.
            // Because we have no information, assume it's something we care about.
            if (error == null) {
                return false;
            }

            // If the error didn't originate in our codebase (Eg. 3rd party script, browser extension, etc.) just ignore it.
            if (!isRatehubError(error)) {
                return true;
            }

            if (isNetworkFailure(error)) {
                return window.isUnloaded
                    ? true // Ignore network failures that occur after the window has been closed.
                    : { group: 'network-failure' };
            }

            return false;
        });

        // We want to disable logging of errors that occur after the user has navigated away from the page.
        // NOTE: Mobile devices do not consistently fire the `unload` event, so this is not a perfect solution.
        window?.addEventListener?.('unload', () => {
            window.isUnloaded = true;
        });

        // Skip all the below if we're in Widget land.
        if (Config.ENABLE_WIDGET_LOADER) {
            return;
        }

        const isHeaderMenuDisabled = !!headerSettings?.disableMenu;
        const isFooterDisabled = !!footerSettings?.isDisabled;
        const isPopularContentDisabled = !!footerSettings?.isPopularContentDisabled;

        // Start updating any clicked language link
        initializeLanguageLinks();

        // Header cannot be fully disabled (only hidden) so always run initializeHeader().
        initializeHeader();

        if (!isHeaderMenuDisabled) {
            initializeHamburger();
        }

        if (!isFooterDisabled) {
            initializeFooter();
        }

        if (!isPopularContentDisabled) {
            initializePopularContentMenu();
        }

        // **** SPECIAL REQUEST FROM QA/PM *****
        // REQUIREMENT: a way to programmatically retrieve how they've been segmented.
        // SOLUTION: Similar concept as in getExperimentSegment - Get all non-pruned experiments and stash them in the window + add to heap
        if (!isServerSide()) {
            const experimentTagNodes = document.head.querySelectorAll('[data-experiment-name]');
            const experimentTags = [];

            for (let i = 0; i < experimentTagNodes.length; i++) {
                // Target only script and style tags as they are the ones we use for injection experiments
                if (experimentTagNodes[i].tagName === 'SCRIPT' || experimentTagNodes[i].tagName === 'STYLE') {
                    const { experimentSegment, experimentName, experimentVariations } = experimentTagNodes[i].dataset;
                    experimentTags.push({
                        slug: experimentName,
                        segment: experimentSegment,
                        variations: toJS(experimentVariations),
                    });

                    // Log their assignment in Heap.
                    if (window.heap) {
                        window.heap.addUserProperties({
                            [`experiment-${experimentName}`]: experimentSegment,
                        });
                    }
                }
            }

            window.__rhInjectedExperiments = experimentTags;
        }
    }, []);

    const router = useRouter();

    useEffect(
        () => {
            router.beforePopState(() => {
                return false; // Disables client-side routing since our SPA already have client routers.
            });
        },
        [ router ],
    );

    return (
        <ErrorBoundary>
            <BannerProvider>
                <SessionStoreProvider>
                    <PageSettingsProvider
                        value={{
                            pageUrl: pageUrl,
                            pageTranslations: pageTranslations ?? pageProps.pageTranslations,
                            locale: pageProps.locale,
                            ...pageSettings,
                        }}
                    >
                        <Component
                            {...pageProps}
                        />
                    </PageSettingsProvider>
                </SessionStoreProvider>
            </BannerProvider>
        </ErrorBoundary>
    );
}

function isRatehubError(error) {
    if (!error.stack) {
        return true; // Some browsers don't support error.stack since its non-standard. According to MDN, most updated browsers do.
    }

    // This assumes that all errors that originate from our codebase will have a stack trace that includes our base URL.
    // NOTE: Using window.location.origin because of local dev environments.
    return error.stack.includes(window.location.origin);
}

function isNetworkFailure(error) {
    if (typeof error.message !== 'string') {
        return false;
    }

    return !![
        'Load failed', // Safari
        'Failed to fetch', // Chrome
        'NetworkError when attempting to fetch resource.', // Firefox
    ].find(message => error.message.includes(message));
}

RatehubApp.propTypes = {
    Component: PropTypes.any.isRequired,
    pageProps: PropTypes.object.isRequired,
    pageUrl: PropTypes.string.isRequired,

    pageTranslations: PropTypes.object,
    experimentDefinitions: PropTypes.array,
};

RatehubApp.defaultProps = {
    pageTranslations: undefined,
    experimentDefinitions: undefined,
};

/**
 * Override the default page initialisation behaviour of Next.js.
 * Perform setup&config for each /page/ Component.
 * getInitialProps is called both on the server AND client-side.
 */
RatehubApp.getInitialProps = async ({ Component, ctx }) => {
    let pagePath = ctx.pathName || ctx.asPath;

    // ensure non-blog pages *do not* have trailing slashes
    // blog pages have traditionally had trailing slash from WP
    // and we do not want to change these because SEO reasons
    if (!/^\/blog(ue)?/.test(pagePath)) {
        pagePath = /\/$/.test(pagePath) ? pagePath.slice(0, -1) : pagePath;
    }

    const pageUrl = `${Config.RATEHUB_BASE_URL}${pagePath}`;

    /**
     * Allow the page to perform any pre-render fetching if not using getStaticPaths/Props
     * If using getStaticPaths/Props, pageProps will equal {} */
    const pageProps = typeof Component?.getInitialProps === 'function' && Component.displayName !== 'ErrorPage'
        ? await Component.getInitialProps({ ...ctx.query })
        : (Component.displayName === 'ErrorPage' ? await Component.getInitialProps(ctx) : {});

    // Either pre-fetched within next.config.js (getInitialProps), OR need to be fetched (getStaticPropPages)
    // ASSUMPTION: fetchExperimentDefinitions uses caching to avoid unnecessary calls.
    const experimentDefinitions = ctx.query.experimentDefinitions
        ?? (Config.ENABLE_EXPERIMENTS
            ? await fetchExperimentDefinitions()
            : []
        );

    // Convert everything to POJO to allow for serialization.
    return {
        pageProps,
        pageUrl,
        pageTranslations: ctx.query.translations, // pageProps.pageTranslations is not populated here
        experimentDefinitions,
    };
};

export default observer(RatehubApp);
