import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Colours from '../definitions/Colours';
import { SECONDARY_STATE_COLOURS as STATE_COLOURS } from '../definitions/ButtonStateColours';
import Button from './Button';


const GLAMAZON_STYLES = css`
    /* Default, Hover/Focus, Active */

    ${props => !props.disabled && `
        color: ${STATE_COLOURS[props.variant].DEFAULT};
        background-color: ${Colours.TRANSPARENT};
        border-color: ${STATE_COLOURS[props.variant].DEFAULT};

        &:hover,
        &:focus {
            color: ${STATE_COLOURS[props.variant].HOVER};
            border-color: ${STATE_COLOURS[props.variant].HOVER};
        }

        &:active {
            color: ${STATE_COLOURS[props.variant].ACTIVE};
            border-color: ${STATE_COLOURS[props.variant].ACTIVE};

            box-shadow: none;
        }
    `}

    /* Disabled */

    ${props => props.disabled && `
        cursor: default;

        color: ${STATE_COLOURS[props.variant].DISABLED};
        border-color: ${STATE_COLOURS[props.variant].DISABLED};
    `}
`;

const AlternateButton = styled(Button)`
    ${GLAMAZON_STYLES}
`;

AlternateButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.oneOf([
        'large',
        'medium',
        'small',
    ]),
    variant: PropTypes.oneOf(Object.keys(STATE_COLOURS)),
    disabled: PropTypes.bool,
};

AlternateButton.defaultProps = {
    size: 'large',
    variant: 'dark',
    disabled: undefined,
};

export default AlternateButton;
