import fetchWithRetry from './fetchWithRetry';


/**
 * A wrapped fetch call for retrieving a JSON response which logs errors and potentially retries transient errors.
 * @param {string} url
 * @param {object} options
 * @param {Boolean} options.canRetry - If we should attempt retries based on specific criteria. Defaults to true.
 * @returns {object}
 */
async function fetchWithErrorHandling(url, options) {
    // Unless specifically told not to, attempt retries
    const response = options?.canRetry !== false
        ? await fetchWithRetry(url, options)
        : await fetch(url, options);

    // Check for failure
    if (!response.ok) {
        throw new FetchError(`Response code was not HTTP 2xx. Received: ${response.status}`, response);
    }

    return response;
}

/** A custom Error class where we can include the response object for logging and introspection */
class FetchError extends Error {
    constructor(message, response) {
        super(message);
        this.response = response;
        this.status = response.status;
        this.url = response.url;
    }
}

export { FetchError };
export default fetchWithErrorHandling;
