import _get from 'lodash.get';
import { observable, flow, when } from 'mobx';

import debounce from './debounce';
import fetchSavedDocumentDetails from './fetchSavedDocumentDetails';
import pushDocument from './pushDocument';
import patchDocument from './patchDocument';
import deleteDocument from './deleteDocument';
import getDocumentDefinitionByPath from './getDocumentDefinitionByPath';
import getDocumentDefinitionByType from './getDocumentDefinitionByType';


/**
 *
 * @param {Object} store
 * @param {Object} documentStub
 * @param {string} documentStub.id
 * @param {string} documentStub.path
 * @param {Object} documentStub.metadata initial information about document during fetch
 * @param {Object} documentStub.document actual full document that has to be downloaded
 * @param {ObservableObject} sessionStore SessionStore
 */
function createDocumentDAO({ id, path, metadata, document = null, isPersisted = false, sessionStore }) {
    const self = observable({
        id,
        path,
        // TODO: metadata will be a property if they're logged in, but that should be fixed at some point. (PRO-237)
        metadata: metadata?.metadata
            ? { ...metadata.metadata, id } // metadata object won't have ID so insert it
            : metadata, // this will just be the document returned by /profile/get-session
        // will be null until fetched
        document,

        // API state
        isFetching: false,
        isSaving: false,
        isDeleting: false,
        isPersisted,
        fetchError: null,
        persistenceError: null,
        deleteError: null,

        _pendingPost: null,
        _patchQueue: [],

        sessionStore,

        // used by api calls
        get documentType() {
            return getDocumentDefinitionByPath(path).TYPE;
        },
        get requiredSchemaVersion() {
            return getDocumentDefinitionByType(self.documentType).SCHEMA_VERSION;
        },
        get schemaVersion() {
            return self.document?.schemaVersion // if document was fetched, use document.schemaVersion
                ?? self.metadata?.schemaVersion // if not, use metadata (document stub from profile)
                ?? null;
        },
        get hasUnsavedChanges() {
            return self.isSaving || self._pendingPost != null || self._patchQueue.length > 0;
        },
        get hasPersistenceError() {
            return self.persistenceError != null;
        },
        get hasFetchError() {
            return self.fetchError != null;
        },
        get hasDeleteError() {
            return self.deleteError != null;
        },

        fetchDocument: flow(function* (modelFactory) {
            self.sessionStore.setUniversalErrorMessage(null);

            // We already downloaded the document so just return it
            if (self.document) {
                return self;
            }

            self.isFetching = true;
            self.fetchError = null;

            try {
                const { documents } = yield fetchSavedDocumentDetails([ {
                    id,
                    documentType: self.documentType,
                    schemaVersion: self.requiredSchemaVersion ?? self.schemaVersion,
                } ]);
                const snapshot = _get(documents, `${path}.${id}`);
                const document = modelFactory ? modelFactory(snapshot) : snapshot;

                self.document = document;
                self.isPersisted = true; // came from server

                return self;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`[DAO] Error fetching document (${id}): ${error.message}`);

                self.isFetching = false;

                self.fetchError = error;
                self.sessionStore.setUniversalErrorMessage(error);

                throw error;
            }
        }),
        pushDocument: flow(function* (snapshot, metadata) {
            self.sessionStore.setUniversalErrorMessage(null);

            self.isSaving = true;
            self.persistenceError = null;

            try {
                // BE will send the updated profile ONLY IF profile-propagation has happened
                // the returned profile doesn't have "documents" node inside of it
                const { profile } = yield pushDocument({
                    ...snapshot,
                    ...(!!metadata && { metadata }),
                }, self.documentType);

                if (metadata != null) {
                    self.metadata = metadata;
                }

                // NOTE: BE will send the updated profile ONLY IF profile-propagation has happened
                // the returned profile doesn't have "documents" node inside of it
                sessionStore._updateProfile(profile);

                self.isSaving = false;
                self.isPersisted = true;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`[DAO] Error pushing document (${id}): ${error.message}`);
                self.isSaving = false;

                self.persistenceError = error;
                self.sessionStore.setUniversalErrorMessage(error);

                throw error;
            }
        }),
        enqueuePost: function(snapshot, metadata = null) {
            // replace the existing snapshot
            // - metadata could be already included in snapshot
            // - or metadata could be sent separately - if so, inject it into snapshot
            self._pendingPost = {
                ...snapshot,
                ...(!!metadata && { metadata }),
            };

            if (metadata != null) {
                self.metadata = metadata;
            }

            // If we get POST call while having patches in the queue
            // we update document using POST call and ignore the patches in the queue
            self._patchQueue.splice(0);

            debouncedFlushChanges(false);
        },
        enqueuePatch: function(patch, metadata) {
            self._patchQueue.push(patch);

            // We need to immediately update the metadata so that when a flush happens
            // it has the most-current metadata: the whole patch queue only has a single slot for the metadata
            if (metadata != null) {
                self.metadata = metadata;
            }

            // Start a flush of our accumulated patches if they don't add any more for a short time.
            // Defined underneath the self closure.
            debouncedFlushChanges(false);
        },
        flushChanges: flow(function* (shouldThrowOnFail = true) {
            // SPECIAL CASE: if a save operation is currently running, wait until it's done.
            if (self.isSaving) {
                yield when(() => self.isSaving === false);
            }

            // SPECIAL CASE: if there was SAVING done, it might have flushed
            if (self._pendingPost == null && self._patchQueue.length === 0) {
                return;
            }

            // SPECIAL CASE: need to ensure we have a baseline document to PATCH.
            if (!self.isPersisted && !self._pendingPost) {
                if (shouldThrowOnFail) {
                    throw new Error(`flushPatches requires document ${id} to be persisted first`);
                } else {
                    // eslint-disable-next-line no-console
                    console.error(`[DAO] flushPatches requires document ${id} to be persisted first`);
                    return;
                }
            }

            self.isSaving = true;
            self.persistenceError = null;
            self.sessionStore.setUniversalErrorMessage(null);

            // this is to check if any POST request arrives while pushDocument
            const currentPost = self._pendingPost;

            if (currentPost) {
                try {
                    const { profile } = yield pushDocument(currentPost, self.documentType);

                    // if there's new POST request arrives before pushDocument finishes
                    // we should NOT set self._pendingPost to null
                    // new self._pendingPost should be dealt with in the next flush
                    if (currentPost === self._pendingPost) {
                        self._pendingPost = null;
                    }
                    self.isPersisted = true;

                    self.sessionStore._updateProfile(profile);
                }
                catch (error) {
                    self.persistenceError = error;
                    self.sessionStore.setUniversalErrorMessage(error);
                    // eslint-disable-next-line no-console
                    console.error(`[DAO] Error pushDocument(POST) (${id}): ${error.message}`);

                    self.isSaving = false;

                    if (shouldThrowOnFail) {
                        throw error;
                    } else {
                        return;
                    }
                }
            }

            // If there's new POST while pushDocument is being made, we don't do patchDocument
            // Example situation - While POST(x) & PATCH(a) is being processed,
            //                     POST(y) & PATCH(b) arrives before pushDocument of POST(x) finishes
            // Resultant         - 1st flush: POST(x)
            //                     2nd flush: POST(y) + PATCH(b)
            // Note: there's a small chance to lose profile metadata propagation in this way
            // But as long as total document(POST call) has the profile metadata, it should be fine
            if (self._pendingPost == null && self._patchQueue.length > 0) {
                const batchedPatches = self._patchQueue.splice(0);
                try {
                    const { profile } = yield patchDocument({
                        documentID: self.id,
                        documentType: self.documentType,
                        documentPatches: batchedPatches,
                        metadata: self.metadata,
                        documentSchemaVersion: self.schemaVersion,
                    });
                    self.sessionStore._updateProfile(profile);
                }
                catch (error) {
                    // if another POST request arrives while queue is being flushed
                    // we don't want the queue back
                    if (self._pendingPost == null) {
                        // If we failed, we must preserve the ORIGINAL order of the patches.
                        self._patchQueue.unshift(...batchedPatches);
                    }

                    self.isSaving = false;

                    // eslint-disable-next-line no-console
                    console.error(`[DAO] Error patching document (${id}): ${error.message}`);
                    self.persistenceError = error;
                    self.sessionStore.setUniversalErrorMessage(error);

                    if (shouldThrowOnFail) {
                        throw error;
                    }
                }
            }
            self.isSaving = false;
        }),
        // NOTE: Auto and Home applications can be deleted in dashboard
        // if you are going to use this function please make sure it's safe to use
        deleteDocument: flow(function* (){
            self.deleteError = null;
            self.sessionStore.setUniversalErrorMessage(null);
            self.isDeleting = true;

            try {
                yield deleteDocument(self.documentType, id);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`[DAO] Error deleting document (${id}): ${error.message}`);

                self.isDeleting = false;

                self.deleteError = error;
                self.sessionStore.setUniversalErrorMessage(error);

                throw error;
            }

            self.isDeleting = false;
        }),
    });

    // This needs to be declared down here so we have reference to self.
    const debouncedFlushChanges = debounce(self.flushChanges);

    // Need to return variable or context is lost if destructured
    return self;
}

export default createDocumentDAO;
