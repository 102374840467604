import Config from '../definitions/Config';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/verify`;

/**
 *
 * @param {string} email
 * @param {string} verificationToken
 * @param {Object} params
 * @param {string} params.lang
 * @param {Object.<string, string>=} params.queryParams
 * @param {Object} documentOptions
 * @param {string=} documentOptions.documentToken
 * @param {string=} documentOptions.documentRedirect
 * @param {string=} variant
 * @returns {Promise<*>}
 */
async function verifyAccount(
    email,
    verificationToken,
    { lang, ...queryParams } = {},
    { documentToken, documentRedirect } = {},
    variant,
) {
    if (!email) {
        throw new Error(`Email is required to verify new account. received: ${email}`);
    }
    if (!verificationToken) {
        throw new Error(`Verification token is required to verify new account. received: ${verificationToken}`);
    }

    const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email,
            verificationToken,
            queryParams,
            documentToken,
            documentRedirect,
            variant,
            lang,
        }),
        credentials: 'include',
    });

    let responseBody;
    try {
        responseBody = await response.json();
    } catch (e) {
        throw new Error(`Unknown account verification failure. Status code: ${response.status}`);
    }

    if (response.ok) {
        return responseBody.data;
    } else {
        throw responseBody;
    }
}

export {
    verifyAccount,
    API_ENDPOINT,
};
