export default {
    'journey.contact' : {
        'model': 'Contact', // registered in common/v1/definition/PropagationModels
        'profileSync': {
            'toProfile' : {
                'enable': true,
                'overwrite': true,
                'properties': [ 'firstName', 'lastName', 'phone', 'email', 'dob' ],
            },
            'fromProfile' : {
                'enable': true,
                // if doc already has, do not overwrite the value,
                // this option applies to each field, not model itself
                // ex) if firstName already exists, do not overwrite
                // ex) if lastName does not exist, write
                'overwrite': false,
                'properties': [ 'firstName', 'lastName', 'phone', 'email', 'dob' ],
            },
            'profilePath': '$', // $ represent root of profile object
        },
    },
    'journey.contact.address' : {
        'profileSync': {
            'toProfile' : {
                'enable': true,
                'overwrite': true,
            },
            'fromProfile' : {
                'enable': true,
                'overwrite': false,
            },
            'profilePath': 'homeAddress',
        },
    },
    'journey.contact.sin' : {
        'encrypt': {
            'bsonType': 'string',
            'algorithm': 'AEAD_AES_256_CBC_HMAC_SHA_512-Deterministic',
        },
    },
};
