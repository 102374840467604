import fetchCityInformation from './fetchCityInformation';
import fetchGeoLocationFromIp from './fetchGeoLocationFromIp';
import getUserCityId from './getUserCityId';
import setUserCityId from './setUserCityId';
import noticeError from './noticeError';


/**
 * Fetch full user city information from api by cookie or by geo ip lookup
*/
async function fetchUserCity(shouldSaveInCookie = true) {
    // Try to grab the CityId from the Ratehub cookie.
    const cityId = getUserCityId();

    if (cityId) {
        const cities = await fetchCityInformation({ id: cityId }, 'fetchUserCity');

        return cities[0];
    }

    const location = await fetchGeoLocationFromIp();

    if (
        !location
        || !location.city
        || !location.province
        // v2 of ip-to-loc api
        || location.country && location.country.iso_code !== 'CA'
    ) {
        return null;
    }

    const cities = await fetchCityInformation({
        slug: location.city.names.en.toLowerCase(),
        province: location.province.code,
    }, 'fetchUserCity');

    // Province param doesn't seem to work to filter to exact province, so we need this extra step
    const cityForProvince = cities.find(city => city.province.code === location.province.code);

    if (!cityForProvince) {
        noticeError(new Error('[fetchUserCity] No city found for province'), {
            location: JSON.stringify(location),
            cities: cities.map(city => ({
                name: city.name,
                province: city.province.code, // important: what if the province code is mismatched (i.e. YT vs YK)?
            })),
        });

        return null;
    }

    // Updates cookie location
    if (shouldSaveInCookie) {
        setUserCityId(cityForProvince.id);
    }

    return cityForProvince;
}
export default fetchUserCity;
