import React, { useState } from 'react';
import { observable } from 'mobx';

import { useBannerContext } from '../components/BannerProvider';
import MessageBanner from '../components/MessageBanner';

import { MessageBannerVariants } from '../definitions/MessageBannerDefinitions';


function useMessageBannerContext() {
    const bannerContext = useBannerContext();

    if (!bannerContext) {
        throw new Error('[useMessageBannerContext] BannerProvider is required as a context provider');
    }

    const [ state ] = useState(() => observable({
        isVisible: false,

        get areAnyBannersVisible() {
            return bannerContext.isVisible;
        },

        show({ variant, message, iconKey, duration, isSticky, isCloseVisible, onHide, ...otherProps }) {
            if (!Object.values(MessageBannerVariants).includes(variant)) {
                throw new RangeError(`[useMessageBannerContext] variant expected to be one of ${JSON.stringify(Object.values(MessageBannerVariants))} but received "${variant}"`);
            }

            state.isVisible = true;

            bannerContext.show({
                children: (
                    <MessageBanner
                        iconKey={iconKey ?? getIconKey(variant)}
                        variant={variant}
                        message={message}
                        onClose={isCloseVisible ? state.hide : undefined}
                        {...otherProps}
                    />
                ),
                duration,
                isSticky,
                // Hide can be called when the duration is completed
                onHide: () => {
                    state.message = false;
                    onHide?.();
                },
            });
        },
        hide() {
            state.isVisible = false;
            bannerContext.hide();
        },
    }));

    return state;
}

function getIconKey(variant) {
    switch (variant) {
        case MessageBannerVariants.ERROR:
        case MessageBannerVariants.WARNING:
            return 'exclamation-mark';
        case MessageBannerVariants.SUCCESS:
            return 'checkmark';
        default:
            return null;
    }
}

export default useMessageBannerContext;
