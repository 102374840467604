import Cookies from 'js-cookie';

import cookieSettings from '../definitions/CookieSettings';


const COOKIE_NAME = 'location';

/**
 * Set user location cookie to a city id
 */
export default function setUserCityId(cityId) {
    Cookies.set(COOKIE_NAME, cityId, cookieSettings);
}
