import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


/**
* Retrieve our location given our IP.
* @returns {Promise}
*/
export default function fetchGeoLocationFromIp() {
    // Error handling occurs within fetchJSON
    return fetchJSON(Config.IP_LOC_API_URL);
}
