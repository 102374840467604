import { types } from 'mobx-state-tree';

import { LanguageEnum } from '../definitions/Languages';
import { GenderEnum } from '../definitions/Genders';
import IsoDate from '../types/IsoDate';
import EmailAddress from '../types/EmailAddress';
import Address from './Address';


const Contact = types.model('Contact', {
    firstName: types.maybe(types.string),
    middleName: types.maybe(types.string),
    lastName: types.maybe(types.string),
    email: types.maybe(EmailAddress),
    phone: types.maybe(types.string),
    phoneExt: types.maybe(types.string),
    secondaryPhone: types.maybe(types.string),
    secondaryPhoneExt: types.maybe(types.string),
    homeAddress: types.maybe(Address),
    dob: types.maybe(IsoDate),
    language: types.maybe(LanguageEnum),
    gender: types.maybe(GenderEnum),
});

export default Contact;
