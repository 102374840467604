import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Config from '../definitions/Config';
import { SessionStoreContext } from '../hooks/useSessionStore';
import useMessageBannerContext from '../hooks/useMessageBannerContext';
import createSessionStore from '../stores/SessionStore';


function SessionStoreProvider({ children, store }) {
    // We use this to signal something is wrong to the user (at the page level).
    // NOTE: May not be declared (Storybook, tests, etc).
    const messageBanner = useMessageBannerContext();
    
    const [ state ] = useState(() =>
        store ?? (
            Config.ENABLE_SESSION_STORE_PROVIDER
                ? createSessionStore(messageBanner)
                : null
        ),
    );

    return (
        <SessionStoreContext.Provider value={state}>
            {children}
        </SessionStoreContext.Provider>
    );
}

SessionStoreProvider.propTypes = {
    children: PropTypes.any.isRequired,
    store: PropTypes.object,
};

SessionStoreProvider.defaultProps = {
    store: undefined,
};

export default SessionStoreProvider;
