import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Colours from '../definitions/Colours';
import IconMapLazy from '../definitions/IconMapLazy';
import IconChooserSizes from '../definitions/IconChooserSizes';
import getIconSizeClassName from '../functions/getIconSizeClassName';


function IconChooser({
    className,
    iconKey,
    size,
    includeOutline,
    stroke,
    fill,
    ...otherProps
}) {
    const Icon = IconMapLazy[iconKey];
    const outlineWidth = includeOutline
        ? '2px'
        : '0';
    const direction = iconKey?.startsWith('arrow')
        ? iconKey.slice(6)  // skip past 'arrow-' to grab 'up', 'right', 'down', 'left'
        : undefined;


    return (
        <If condition={Icon}>
            {/*
                next/dynamic lazy loading (internally React.Lazy) won't allow us to pass props
                to the loading component, so the placeholder for each lazy icon doesn't know what size to be.
                We need this wrapper div to reserve space while the icon loads, to prevent jank.
            */}
            <div className={classNames(className, getIconSizeClassName(size))}>
                <Icon
                    className="rh-width-100p rh-height-auto"
                    stroke={stroke}
                    fill={fill}
                    outlineWidth={outlineWidth}
                    direction={direction}
                    {...otherProps}
                />
            </div>
        </If>
    );
}

IconChooser.propTypes = {
    className: PropTypes.string,
    iconKey: PropTypes.oneOf(Object.keys(IconMapLazy)).isRequired,
    size: PropTypes.oneOf(Object.keys(IconChooserSizes)),
    includeOutline: PropTypes.bool,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};

IconChooser.defaultProps = {
    className: undefined,
    size: 'XL',
    includeOutline: true,
    stroke: Colours.BLUEBERRY,
    fill: Colours.TRANSPARENT,
};

export default IconChooser;
