import { observable, flow, toJS, when } from 'mobx';
import _get from 'lodash.get';

import { addProfileDataToDocumentSnapshot } from '@ratehub/documents/utilities';
import timeoutAsPromise from '../functions/timeoutAsPromise';
import navigateWindowTo from '../functions/navigateWindowTo';
import noticeError from '../functions/noticeError';
import trackHeapEvent from '../functions/trackHeapEvent';
import fetchSession from '../functions/fetchSession';
import { authenticateMagicToken } from '../functions/authenticateMagicToken';
import { verifyAccount } from '../functions/verifyAccount';
import deauthenticateUser from '../functions/deauthenticateUser';
import signInBySocialService from '../functions/signInBySocialService';
import createDocumentDAO from '../functions/createDocumentDao';
import pushProfileUpdate from '../functions/pushProfileUpdate';
import areFirstPartyCookiesEnabled from '../functions/areFirstPartyCookiesEnabled';
import getDocumentDefinitionByType from '../functions/getDocumentDefinitionByType';
import fetchShouldForceLogin from '../functions/fetchShouldForceLogin';
import fetchUserCity from '../functions/fetchUserCity';
import getVisitorId from '../functions/getVisitorId';
import setUserCityId from '../functions/setUserCityId';
import { Experiments } from '../functions/getExperimentSegment';
import sendLog from '../functions/sendLog';
import DOCUMENTS from '../definitions/Documents';
import UNIVERSAL_ERROR_MESSAGES from '../definitions/UniversalErrorMessages';
import SOCIAL_PROVIDERS from '../definitions/SocialProviders';
import { MessageBannerVariants } from '../definitions/MessageBannerDefinitions';
import Config from '../definitions/Config';


const PROFILE_DEFAULTS = {
    id: null,
    uid: null,
    visitorId: null,

    email: null,
    phone: null,

    firstName: null,
    lastName: null,

    userLocation: null,

    dob: null,
};


/**
 * Create a new instance of our Session Store, which provide an interface to our Profile Service.
 * @param {Object} messageBannerState instance of the object returned from useMessageBannerContext
 * @returns
*/
function createSessionStore(messageBannerState) {
    const self = observable({
        // Public properties
        profile: { ...PROFILE_DEFAULTS },

        // Cookie status
        hasFirstPartyCookiesEnabled: areFirstPartyCookiesEnabled(),

        // Private, will hold document DAOs.
        _documents: {},
        // Private, read-only
        _roles: [],

        // API-related state (fetch session)
        isFetchingSession: false,
        fetchingSessionError: null,
        hasFetchedSession: false,
        // API-related state (should force login)
        isFetchingShouldForceLogin: false,
        fetchingShouldForceLoginError: null,

        isLoggingOut: false,
        hasLoggedOut: false,

        get isLoggedIn() {
            return self.profile.uid != null;
        },
        get email() {
            return self.profile?.email;
        },
        get shortName() {
            if (self.profile.firstName && self.profile.lastName) {
                return `${self.profile.firstName.substring(0, 1)}${self.profile.lastName.substring(0, 1)}`;
            }
            return null;
        },
        get universalErrorMessage() {
            return messageBannerState?.message;
        },

        get roles() {
            return self._roles ?? [];
        },

        /**
         * Check if we have the specified role.
         *
         * @param role
         * @returns {boolean}
         */
        hasRole(role) {
            return self.roles.includes(role);
        },

        /**
         * Check if we have any of the roles in the array.
         *
         * This is useful for situations where multiple roles can access the same system, but we don't require a user to have all of them.
         *
         * @param {Array<string>} roles
         * @returns {boolean}
         */
        hasOneOfRoles(roles) {
            return roles.some(self.hasRole);
        },

        /**
         * Update our contents by retreiving information from the Profile service.
         * @param {boolean} forced if we should re-fetch even if already fetched
         * @returns {Promise<boolean>}
         */
        fetchSession: flow(function* (forced = false) {
            if (typeof window === 'undefined') {
                return false; // don't fetch on SSR
            }

            // Abort if already fetched
            if (self.hasFetchedSession && !forced) {
                return self.fetchingSessionError === null;
            }

            // Ignore request to fetch if we're already fetching
            if (self.isFetchingSession) {
                yield when(() => self.isFetchingSession === false);
                return self.fetchingSessionError === null;
            }

            self.isFetchingSession = true;
            self.fetchingSessionError = null;
            self.setUniversalErrorMessage(null);

            try {
                const { profile, roles } = yield fetchSession();

                // TODO: Remove this when backend for mortgages unidoc is cleaned up.
                // the id for application vs journey is the same which will cause conflicts
                // when trying to find a document by id.
                if (profile.documents?.mortgages) {
                    delete profile.documents.mortgages.application;
                }

                // location(base-ui's CityField object) could have been saved before Profile schema established
                if (profile.location) {
                    if (!profile.homeAddress) {
                        profile.homeAddress = {
                            city: profile.location.name,
                            province: profile.location.province?.code,
                        };
                    }
                    delete profile.location;
                }

                // Note: This is not a propagated field like homeAddress.
                // TODO: Remove these when shim no longer needed
                profile.userLocation = profile.userLocation ?? (yield fetchUserLocation());
                profile.propertyLocation = profile.propertyLocation ?? profile.userLocation;

                // Addresses XSS Vulerability in a temporary way.
                // See: https://ratehub.atlassian.net/browse/PRO-642
                // This gets rendered within the profile drop-down ("Hi, <name>") using renderMessage (dangerouslySetInnerHTML)
                // WORKAROUND: filter out any open/close scripting tags and/or "
                if (profile.firstName) {
                    profile.firstName = profile.firstName.replace(/[<>"]/g, '');
                }
                if (profile.lastName) {
                    profile.lastName = profile.lastName.replace(/[<>"]/g, '');
                }

                self.profile = profile;
                self._roles = roles;

                // Build/update our DAOs
                self._documents = createOrUpdateDocuments(profile.documents, self);

                // Identify the user in hotjar once we've fetched the session
                //  We're doing this within fetchSession since we'd like
                //  to keep track of whether the user is logged in or not.
                identifyHotjarUser(self);

                // We don't currently support accessing the documents like this
                delete self.profile.documents;

                self.hasFetchedSession = true;
            } catch (error) {
                self.setUniversalErrorMessage(error);
                self.fetchingSessionError = error;

                // eslint-disable-next-line no-console
                console.error(`[SessionStore] Error loading profile: ${error.message}`, error);
            }

            self.isFetchingSession = false;

            return self.fetchingSessionError === null;
        }),

        /**
         *
         * @param {string} path
         *
         * @returns {Array<Object>} document stubs
         */
        getDocumentMetadataByPath(path) {
            return Object.values(self._documents)
                .filter(document => document.path === path)
                .map(document => document.metadata);
        },
        getDocumentType(id) {
            return self._documents[id]?.documentType;
        },
        getDocumentPersistenceError(id) {
            return self._documents[id]?.persistenceError;
        },
        isDocumentLoading(id) {
            return self._documents[id]?.isLoading;
        },
        isDocumentSaving(id) {
            return self._documents[id]?.isSaving;
        },
        isDocumentDeleting(id) {
            return self._documents[id]?.isDeleting;
        },
        isDocumentPersisted(id) {
            return self._documents[id]?.isPersisted;
        },
        hasDocumentUnsavedChanges(id) {
            return self._documents[id]?.hasUnsavedChanges;
        },
        hasDocumentPersistenceError(id) {
            return self._documents[id]?.hasPersistenceError;
        },
        hasDocumentFetchError(id) {
            return self._documents[id]?.hasFetchError;
        },
        hasDocumentDeleteError(id) {
            return self._documents[id]?.hasDeleteError;
        },
        /**
         * Check if user needs to login based on their document.
         * Note: Please save document changes before running this function.
         *
         * @param {string} documentId
         * @param {object} options
         * @param {string} options.documentRedirect
         * @param {object} options.queryParams
         */
        shouldForceLogin: flow(function* (documentId, { documentRedirect, queryParams } = {}) {
            const documentDao = self._documents[documentId];

            if (!documentDao) {
                throw new Error(`[SessionStore.shouldForceLogin] document with id "${documentId}" does not exist.`);
            }

            if (self.isLoggedIn) {
                return false; // nothing to do, they're already logged in.
            }

            // API depends on document information (ie. email), but we can't make assumptions about how they are saving the document.
            // This puts the onus on the user to flush any document changes before calling this API.
            if (documentDao.hasUnsavedChanges) {
                throw new Error(
                    '[SessionStore.shouldForceLogin] Please save any pending document changes before calling shouldForceLogin.',
                );
            }

            self.isFetchingShouldForceLogin = true;
            self.fetchingShouldForceLoginError = null;

            try {
                return yield fetchShouldForceLogin({
                    documentId,
                    documentType: documentDao.documentType,
                    documentRedirect,
                    queryParams,
                });
            } catch (error) {
                self.fetchingShouldForceLoginError = error;

                // eslint-disable-next-line no-console
                console.error(
                    `[SessionStore.shouldForceLogin] Error fetching should force login: ${error.message}`,
                    error,
                );

                // We should not block the user from progressing if there was an error.
                return false;
            } finally {
                self.isFetchingShouldForceLogin = false;
            }
        }),
        /**
         * Fetch a document by its ID, optionally using a factory on the raw document payload.
         *
         * @param {string} id
         * @param {(snapshot: any) => any}
         */
        fetchDocumentById: flow(function* (id, factory) {
            // Ensure we have an up-to-date document list.
            yield self.fetchSession();
            if (self.fetchingSessionError) {
                throw new Error(
                    `[SessionStore] Error during fetch session for document id: "${id}". You may need to re-fetch the session`,
                );
            }

            const documentDao = self._documents[id];

            if (!documentDao) {
                // Returns null instead of error because some BUs treat document not found as an expected behaviour
                // and shouldn't trigger error states
                return null;
            }

            yield documentDao.fetchDocument(factory);

            return documentDao.document;
        }),
        /**
         * Push document.
         * @deprecated please use startSavingDocumentChanges instead.
         * @param {Object} document
         * @param {string} path
         * @param {Object}} metadata object
         *     Please use metadata.profile if you want to propagate profile data
         */
        pushDocument: flow(function* (document, path, metadata) {
            try {
                if (!self._documents[document.id]) {
                    self._documents[document.id] = createDocumentDAO({
                        id: document.id,
                        path,
                        document,
                        sessionStore: self,
                    });
                }

                const documentDao = self._documents[document.id];

                // TODO: In future, we want to check if we have
                // email in document and identify user with email if we do
                yield documentDao.pushDocument(document, metadata);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('[SessionStore] pushDocument error : ', error);

                throw error;
            }

            self.isPushingDocument = false;
        }),
        // NOTE: Auto and Home applications can be deleted in dashboard
        // if you are going to use this function please make sure it's safe to use
        deleteDocument: flow(function* (id) {
            // delete doc in server first and then delete from sessionStore
            // assumption - server always has the doc if sessionStore has it
            try {
                if (!self._documents[id]) {
                    throw new Error(`No document with id "${id}" found in cached profile`);
                }

                yield self._documents[id].deleteDocument();
                delete self._documents[id];
            } catch (error) {
                noticeError(error, {
                    message: '[SessionStore] deleteDocument error',
                    documentId: id,
                });

                throw error;
            }
        }),
        /**
         * Update the profile details on the server.
         * If it succeeds, update our local representation with those changes.
         * @param {Object} values
         */
        pushProfileUpdate: flow(function* (values) {
            self.setUniversalErrorMessage(null);

            // ASSUMPTION: if it doesn't throw, it suceeded.
            try {
                yield pushProfileUpdate(values);

                // Update our cache.
                self._updateProfile(values);
            } catch (error) {
                noticeError(error, {
                    message: '[SessionStore] pushProfileUpdate error',
                    ...values,
                });

                self.setUniversalErrorMessage(error);

                throw error;
            }
        }),
        /**
         * Authenticate.
         * @param {Object} parameters
         * @param {string} parameters.email
         * @param {string} parameters.magicToken
         * @param {Object} parameters.queryParams
         * @param {string} parameters.variant
         */
        authenticate: flow(function* ({ email, magicToken, queryParams = {}, documentOptions, variant } = {}) {
            const { document } = yield authenticateMagicToken(email, magicToken, queryParams, documentOptions, variant);
            self._documents = null;
            yield self.fetchSession(true); // re-fetch session after logging in
            return document?.id;
        }),
        /**
         * Sign up for new user
         * document is ignored here since document will be loaded in redirected page such as wizard
         * @param {Object} parameters
         * @param {string} parameters.email
         * @param {string} parameters.magicToken
         * @param {Object} parameters.queryParams
         * @param {string} parameters.variant
         * @returns {Object} returns { profile, document }
         */
        verify: flow(function* ({ email, validationToken, queryParams = {}, documentOptions, variant } = {}) {
            const { document } = yield verifyAccount(email, validationToken, queryParams, documentOptions, variant);
            self._documents = null;
            yield self.fetchSession(true); // re-fetch session after signing up
            return document?.id;
        }),
        signInBySocialService: flow(function* ({ providerToken, redirectTo, socialProvider = SOCIAL_PROVIDERS.GOOGLE, document = null, documentType = null }) {
            try {
                trackHeapEvent('[GoogleSSOExperiment] Google-Sign-In requested', {
                    loginSource: socialProvider,
                });

                yield signInBySocialService({ providerToken, socialProvider, document, documentType });

                // TODO: remove this after Experiment is done
                trackHeapEvent('[GoogleSSOExperiment] Google-Sign-In verified', {
                    loginSource: socialProvider,
                });

                // TODO: redirectTo is required for now
                //   Refreshing/redirecting page is to prevent any wrong-doing of page
                //   while transitioning from Guest to Verified user
                navigateWindowTo(redirectTo);
            } catch (error) {
                noticeError(error, {
                    message: `[SessionStore] SSO sign in error: ${error.message}`,
                    socialProvider,
                    providerToken,
                });
            }
        }),
        /**
         * Logout.
         * @param {boolean} shouldRefetch whether to re-fetch Session after clearing up cookies
         * When User get redirected to new page after logout, Session is to be re-fetched in new page
         * @returns {Promise<boolean>} isSuccessful
         */
        logout: flow(function* (shouldRefetch = true) {
            self.isLoggingOut = true;

            try {
                yield deauthenticateUser();

                self._documents = {};
                self.profile = { ...PROFILE_DEFAULTS };
                self._roles = [];
            } catch (error) {
                self.isLoggingOut = false;

                // eslint-disable-next-line no-console
                console.error(`[SessionStore] Error logging out: ${error.message}`, error);

                self.setUniversalErrorMessage(error);

                return false;
            }

            if (shouldRefetch) {
                try {
                    yield self.fetchSession(true); // re-fetch session after logging out
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(
                        `[SessionStore] Error re-fetching session after logging out: ${error.message}`,
                        error,
                    );

                    self.setUniversalErrorMessage(error);
                    return false;
                }
            }

            self.hasLoggedOut = true;
            self.isLoggingOut = false;

            return true;
        }),
        setUniversalErrorMessage(error) {
            // SPECIAL CASE: clearing a message.
            if (error == null) {
                messageBannerState?.hide();
                return;
            }

            // Show the appropriate message.
            messageBannerState?.show({
                message: getMessageForError(error),
                variant: MessageBannerVariants.ERROR,
            });

            // Our services are unable to authenticate users with disabled cookies.
            // Do NOT set off alarms: there is nothing wrong with our system.
            if (self.hasFirstPartyCookiesEnabled || ![ 401, 403 ].includes(error.status)) {
                // TODO: @mccullough-ratehub - do not error if (profile BFF and status is 401 or 403 and hasFirstPartyCookiesEnabled disabled)
                noticeError(error, {
                    message: `[SessionStore]: ${error.message}`,

                    // Include profile information to cross-reference with BE
                    profileDetails: {
                        ...toJS(self.profile),
                    },
                });
            }
        },
        // TODO: we are currently saving this data in Cookie
        //       we want to save this data in db as a part of profile
        // userLocation: { city: string(name), province: string(code), cityId: string, citySlug: string, isFromGeoIP?: true }
        setUserLocation(userLocation) {
            if (userLocation == null) {
                return;
            }

            self.profile.userLocation = userLocation;

            // save it in Cookie
            if (!userLocation.isFromGeoIP) {
                setUserCityId(userLocation.cityId);
            }
        },
        // Please do not use this function outside of SessionStore or DAO
        _updateProfile(profile) {
            if (typeof profile === 'object' && Object.keys(profile ?? {}).length > 0) {
                // eslint-disable-next-line no-unused-vars
                const { documents, ...updatedValues } = profile; // this is to exclude documents without mutating profile object

                self.profile = {
                    ...toJS(self.profile),
                    ...updatedValues,
                };
            }
        },
        /**
         * Note: This expects you to be using `startSavingDocumentChanges`; the use case is when you have to do something only after the document changes are saved (eg. make an API call).
         *
         * @param id
         * @returns {Promise<void>} Resolves on success.
         */
        async waitForDocumentToFinishSaving(id) {
            if (!self._documents[id]) {
                throw new Error(
                    `No document with id "${id}" found in cached profile. You may need to re-fetch the session.`,
                );
            }

            // This will ensure that any queued microtasks which result in mutations to the model are allows to run.
            await Promise.resolve();

            if (!self.hasDocumentUnsavedChanges(id)) {
                return; // no changes to wait for
            }

            // hasUnsavedChanges will be true if there's an error, so check if there's an error to resolve.
            await when(() => self.hasDocumentUnsavedChanges(id) === false || self.hasDocumentPersistenceError(id));

            if (self.hasDocumentPersistenceError(id)) {
                const error = self.getDocumentPersistenceError(id);

                sendLog(
                    `[SessionStore] error on waitForDocumentToFinishSaving: ${self.getDocumentType(id)} ${id}`,
                    { error: error ? JSON.stringify(error.stack ?? error.message ?? error) : 'N/A' }
                );
                throw new Error(`[SessionStore.waitForDocumentToFinishSaving] error while saving document "${id}"`);
            }
        },
        addProfileDataToDocumentSnapshot(documentType, snapshot) {
            const schemaOptions = getDocumentDefinitionByType(documentType).SCHEMA_OPTIONS ?? null;
            return addProfileDataToDocumentSnapshot(snapshot, toJS(self.profile), schemaOptions);
        },
    });

    // Prevent fetching profile on SSR.
    // Also don't bother starting reaction.
    if (typeof window !== 'undefined') {
        // We need to catch exceptions here to prevent uncaught promise rejection warnings
        // eslint-disable-next-line no-console
        self.fetchSession().catch(console.error);
    }

    return self;
}

/**
 * Create a map of Data Access Objects (indexed by their .id) for all documents in a profile
 * @param {Object} documents map of document stubs to create from (index by their path)
 * @param {Object} sessionStore sessionStore caller which is being updated
 * @returns {Object} new map of DAOs
 */
function createOrUpdateDocuments(documents, sessionStore) {
    // Only build DAOs for paths we recognize
    return Object.values(DOCUMENTS).map(doc => doc.PATH).reduce((result, path) => {
        // Build a DAO for each Document under this path
        const documentsInPath = Object.values(_get(documents, path) ?? {});
        documentsInPath.forEach(document => {
            // Preserve the existing DAO (if available) to prevent orphaned references
            let dao = Object.values(sessionStore._documents ?? {}).find(d => d.id === document.id);
            if (dao) {
                dao.metadata = document;
            } else {
                dao = createDocumentDAO({
                    id: document.id,
                    metadata: document,
                    path,
                    isPersisted: true,
                    sessionStore,
                });
            }

            result[document.id] = dao;
        });

        return result;
    }, {});
}

// Temporary shim for profile.userLocation
async function fetchUserLocation() {
    try {
        const city = await Promise.race([
            fetchUserCity(false), // false: do not save city in Cookie
            // Abort if its going to take too long.
            timeoutAsPromise(4000).then(() => {
                throw new Error('Timed out while fetching geo-location; Aborting!');
            }),
        ]);

        if (!city) {
            return null; // could not locate user
        }

        return {
            city: city.name,
            // TODO: Temporary fix for code that relies on city.slug
            citySlug: city.slug,
            // TODO: location cookie keeps city id
            cityId: city.id,
            province: city.province.code,
            isFromGeoIP: true,
        };
    } catch (error) {
        // eslint-disable-next-line no-console
        noticeError(error, {
            message: '[SessionStore.getUserLocation] Error getting user location',
        });

        return null; // error fetching user location should not crash SessionStore
    }
}

/**
 * Get the i18n message to show to users when an error has occured
 * @param {Error} error
 * @returns {Message}
 */
function getMessageForError(error) {
    // Certain kinds of Profile API Errors have different instructions.
    if (error.url?.match(`${Config.PROFILE_BFF_URL}`)) {
        if (error.status === 498) { // auth token error
            return UNIVERSAL_ERROR_MESSAGES.RECOVERABLE_BY_LOGOUT;
        } else if (
            error.status === 401 // cloudflare service unavailable
            || error.status === 504 // cloudflare gateway time out
            || error.status === 524 // cloudflare timeout
        ) {
            return UNIVERSAL_ERROR_MESSAGES.RECOVERABLE_BY_TRY_AGAIN;
        }
    }

    // Everything else falls under "hopefully refresh browser fixes it".
    return UNIVERSAL_ERROR_MESSAGES.RECOVERABLE_BY_REFRESH;
}

/* Adds unique attributes to hotjar so we can track
    more information about a user */
function identifyHotjarUser(sessionStore) {
    // window.hj has to exist for us to identify the user in hotjar
    if (typeof window?.hj === 'undefined') {
        return;
    }

    // Use visitorId as unique identifier
    const visitorId = getVisitorId();

    // Documentation for the identify API:
    // https://help.hotjar.com/hc/en-us/articles/360033640653#making-calls-to-identify
    window.hj('identify', visitorId, {
        isLoggedIn: sessionStore.isLoggedIn,

        // Hotjar only accepts strings, numbers, and bools
        //  we need to cast our experiments to a string so its available
        experiments: JSON.stringify(Experiments.toJSON()),
    });
}

export default createSessionStore;
