import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/magic-sign-in`;

/**
 *
 * @param {string} email
 * @param {string} magicToken
 * @param {Object} params
 * @param {string} params.lang
 * @param {Object.<string, string>=} params.queryParams
 * @param {Object} documentOptions
 * @param {string=} documentOptions.documentToken
 * @param {string=} documentOptions.documentRedirect
 * @param {string=} variant
 * @returns {Promise<*>}
 */
async function authenticateMagicToken(
    email,
    magicToken,
    { lang, ...queryParams } = {},
    { documentToken, documentRedirect } = {},
    variant,
) {
    if (!email) {
        throw new Error(`Email is required to log in with a magic link. received: ${email}`);
    }
    if (!magicToken) {
        throw new Error(`Magic token is required to log in with a magic link. received: ${magicToken}`);
    }

    const jsonResponse = await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
                magicToken,
                queryParams,
                documentToken,
                documentRedirect,
                variant,
                lang,
            }),
            credentials: 'include',
        },
    }, 'authenticateMagicToken');


    return jsonResponse.data;
}

export {
    authenticateMagicToken,
    API_ENDPOINT,
};
