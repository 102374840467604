import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import MessagePropType from '../definitions/MessagePropType';
import renderMessage from '../functions/renderMessage';


function Button({ onClick, className, message, children, size, type, shouldLowerCaseMessage, ...otherProps }) {
    const intl = useIntl();

    const sizeClasses = SIZE_CLASSES[size] ?? [];

    return (
        <Container
            className={classNames(className, ...DEFAULT_CLASSES, ...sizeClasses)}
            type={type}
            onClick={onClick}
            {...renderMessage(message, intl, children, { shouldLowerCaseMessage })}
            {...otherProps}
        />
    );
}

Button.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf([
        'large',
        'medium',
        'small',
    ]),
    // Either one can be passed, children will always take precedence
    message: MessagePropType,
    // PropTypes.node is incorrect, using any as temporary fix.
    // See https://ratehub.atlassian.net/browse/PINGU-254
    // Prefer to use PropTypes.elementType which is not available without update.
    children: PropTypes.any,
    type: PropTypes.oneOf([
        'button',
        'submit',
        'reset',
    ]),
    shouldLowerCaseMessage: PropTypes.bool,
};

Button.defaultProps = {
    className: undefined,
    size: 'large',
    message: undefined,
    children: undefined,
    onClick: undefined,
    type: 'submit',
    shouldLowerCaseMessage: true,
};

const DEFAULT_CLASSES = [
    'rh-box-sizing-border-box',
    'rh-width-100p',
    'rh-border-radius-8px',
    'rh-border-width-2px',
    'rh-outline-none',
    'weight-medium',
];

const SIZE_CLASSES = {
    'small': [
        'rh-min-height-2_5',
        'rh-px-1',
        'rh-py-0_5',
        'rh-font-size-s',
    ],
    'medium': [
        'rh-min-height-3',
        'rh-px-1_5',
        'rh-py-0_5',
        'rh-font-size-s',
    ],
    'large': [
        'rh-min-height-4',
        'rh-px-1_5',
        'rh-py-0_5',
        'rh-font-size-m',
    ],
};

const TRANSITION_DURATION = '300ms';

const Container = styled.button`
    transition:
        border-color ${TRANSITION_DURATION},
        color ${TRANSITION_DURATION},
        background-color ${TRANSITION_DURATION};
`;

export default observer(Button);
export { TRANSITION_DURATION };
