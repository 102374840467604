/* eslint-disable */
import React from 'react';
import Typography from './Typography';


/**
 * @private
 * Used to "generate" a "unique" key.
 * This is done instead of uuid() for performance reasons:
 * There are a very large number of these elements needing to be generated
 * I believe it's a safe assumption we will not generate more than 2^53 calls in a single SPA.
 */
let key = 0;


// We are assigning keys to all of these entries to prevent React errors when any tag is used WITHIN another tag.
// EXAMPLES:
// <p>hello</p><p>Hi</p> is fine.
// <p><strong>Hi</strong></p> will generate an error.
// https://github.com/formatjs/formatjs/issues/1549
// https://github.com/formatjs/formatjs/issues/1623

// Hopefully, this can be solved within react-intl eventually.
// This is *partially* mitigated by adding wrapRichTextChunksInFragment={true} to <IntlProvider> usage.
// https://formatjs.io/docs/react-intl/components/#wraprichtextchunksinfragment

export default {
    p: str => <p key={++key}>{str}</p>,
    h1: str => <h1 key={++key}>{str}</h1>,
    h2: str => <h2 key={++key}>{str}</h2>,
    h3: str => <h3 key={++key}>{str}</h3>,
    h4: str => <h4 key={++key}>{str}</h4>,
    h5: str => <h5 key={++key}>{str}</h5>,

    span: str => <span key={++key}>{str}</span>,
    div: str => <div key={++key}>{str}</div>,

    b: str => <b key={++key}>{str}</b>,
    strong: str => <strong key={++key}>{str}</strong>,

    u: str => <u key={++key}>{str}</u>,

    ul: str => <ul key={++key}>{str}</ul>,
    ol: str => <ol key={++key}>{str}</ol>,
    li: str => <li key={++key}>{str}</li>,

    dl: str => <dl key={++key}>{str}</dl>,
    dt: str => <dt key={++key}>{str}</dt>,
    dd: str => <dd key={++key}>{str}</dd>,

    small: str => <small key={++key}>{str}</small>,

    i: str => <i key={++key}>{str}</i>,
    em: str => <em key={++key}>{str}</em>,
    output: str => <output key={++key}>{str}</output>,

    time: str => <time key={++key}>{str}</time>,


    // requires that the given string is also a valid Date format
    // note that many short date strings, like '20-07-24', are NOT recognized as dates. '2020-07-24' would be.
    longDate: longDate => {
        const theDate = new Date(longDate)

        return isNaN(theDate.getTime())
            ? <time key={++key}>{longDate}</time>
            : <time key={++key} dateTime={theDate.toISOString().slice(0, 10)}>{longDate}</time>
    },

    // Genesis: in French, numbers are separated by a space, not a comma. A number might otherwise be wrapped onto a new line.
    nowrap: str => <span key={++key} className={Typography.CLASSES.NO_WRAP}>{str}</span>,

    'line-through': str => <span key={++key} className='rh-text-decoration-line-through'>{str}</span>,

    // these substitutions should appear within {}, not <>, within the message
    newline: <br />,
    nbsp: <>&nbsp;</>,
    nbhy: <>‑</>,
}
