import Cookies from 'js-cookie';


const COOKIE_NAME = 'location';

/**
 * Get user city id from cookie
 */
export default function getUserCityId() {
    return Cookies.get(COOKIE_NAME);
}
