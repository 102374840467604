import React, { 
    useEffect,
    useState, 
    useContext,
    createContext,
} from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';

import scrollToTop from '../functions/scrollToTop';


const Context = createContext();

function BannerProvider({ children }) {
    const state = useLocalState();

    return (
        <Context.Provider value={state}>
            {children}
        </Context.Provider>
    );
}

BannerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useLocalState() {
    const [ state ] = useState(() => observable({
        isVisible: false,
        children: null,
        isSticky: null,
        
        // Since hide can occur via the timeout, we provide
        //   a callback in case any clean up is needed
        _onHide: null,

        _timer: null,


        show({ children, duration, isSticky, onHide }) {
            // Clean up previous banner state
            state.clear();

            if (!children) {
                throw new Error('[BannerProvider] children are required to render the banner');
            }

            state.isVisible = true;

            // With a component that uses size-me we have to use a render function
            //  to delay the rendering of the JSX until its inside a component.
            //  Otherwise, size-me will throw since there's no valid parent elements.
            // So we're just going to always use render functions for children
            state.children = typeof children !== 'function' ? () => children : children;
            
            state.isSticky = isSticky;
            state._onHide = onHide;

            if (duration != null) {
                state._timer = setTimeout(state.hide, duration);
            }

            // REQUIREMENT: If we're visible and not sticky, scroll to the 
            //  top of the page so the banner is visible to the user
            if (!isSticky) {
                scrollToTop(false);
            }
        },
        hide() {
            state.isVisible = false;

            if (typeof state._onHide === 'function') {
                state._onHide();
                state._onHide = null;
            }
        },
        // Clear is separated for cases where we use an animated container
        //  We cannot fade out if children is null, so we have to clear them after the animation
        clear() {
            state.children = null;
            state.isSticky = null;
            
            if (state._timer) {
                clearTimeout(state._timer);
                state._timer = null;
            }
        },
    }));

    // When we unmount, clear the internal state
    //  and specifically clear any timers that might be running
    useEffect(() => {
        return state.clear;
    }, [ state ]);

    return state;
}

function useBannerContext() {
    return useContext(Context);
}

export { useBannerContext };
export default BannerProvider;
