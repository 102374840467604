import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import MessagePropType from '../definitions/MessagePropType';
import AnchorAnimation from '../styles/AnchorAnimation';
import TextElement from './TextElement';


const VARIANT_LIGHT = 'light';
const VARIANT_DARK = 'dark';

const WEIGHT_REGULAR = 'regular';
const WEIGHT_MEDIUM = 'medium';

const SIZES = {
    NORMAL: 'normal',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    HEADING: 'heading',
};

function Paragraph({ variant, message, children, size, weight, as, className, ...otherProps }) {
    return (
        <Paragraph.TextElement
            variant={variant}
            message={message}
            size={size}
            forwardedAs={as}
            className={classNames(
                'rh-mx-0',
                {
                    'rh-fg-blackberry': variant === VARIANT_DARK,
                    'rh-fg-coconut': variant === VARIANT_LIGHT,
                    'weight-regular': weight === WEIGHT_REGULAR,
                    'weight-medium': weight === WEIGHT_MEDIUM,
                    'rh-my-1 rh-text-s': size === SIZES.SMALL,
                    'rh-my-1 rh-text-m': size === SIZES.MEDIUM,
                    'rh-my-1_5 rh-text-l': size === SIZES.NORMAL,
                    'rh-text-xl': size === SIZES.LARGE,
                    'rh-title-3xl weight-bold': size === SIZES.HEADING,
                },
                className,
            )}
            {...otherProps}
        >
            {children}
        </Paragraph.TextElement>
    );
}

Paragraph.TextElement = styled(TextElement)`
    // Line heights seem made up, but also unwise as our fonts get smaller at smaller screen sizes
    line-height: 26px;

    ${props => props.size === SIZES.SMALL && `
        line-height: 20px;
    `}

    ${props => props.size === SIZES.MEDIUM && `
        line-height: 24px;
    `}

    ${props => props.size === SIZES.LARGE && `
        line-height: 32px;
    `}

    ${props => props.size === SIZES.HEADING && `
        line-height: 48px;
    `}

    // Inappropriate familiarity :(
    a {
       ${AnchorAnimation}
    }
`;

Paragraph.propTypes = {
    variant: PropTypes.oneOf([
        VARIANT_LIGHT,
        VARIANT_DARK,
    ]),
    message: MessagePropType,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    size: PropTypes.oneOf(
        Object.values(SIZES),
    ),
    weight: PropTypes.oneOf([
        WEIGHT_REGULAR,
        WEIGHT_MEDIUM,
    ]),
    as: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

Paragraph.defaultProps = {
    variant: VARIANT_DARK,
    message: undefined,
    children: undefined,
    size: SIZES.NORMAL,
    weight: WEIGHT_REGULAR,
    as: 'p',
    className: undefined,
};

export default Paragraph;
export { SIZES };
